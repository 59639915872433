<template>
  <EmailLoader>
    <p>Great news! Your Product has been approved!</p>

    <p>You can access your products information from the button below.</p>

    <div class="text-center">
      <b-button variant="primary">
        Products
      </b-button>
    </div>
  </EmailLoader>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import EmailLoader from '../setup/loader.vue'

export default {
  components: {
    BButton,
    EmailLoader,
  },
}
</script>
